import { OpenLoginPopUp, CloseLoginPopUp, OPEN_OTHER_MODAL, CLOSE_OTHER_MODAL } from "Redux/constants/tendergrid/LogInModalConstant"
import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";

export const openOtherModal = () => ({ type: OPEN_OTHER_MODAL });
export const closeOtherModal = () => ({ type: CLOSE_OTHER_MODAL });


const initialState = {
    isLogInPopupOpen: false,
    isOtherModalOpen: false,
}



export default function LoginModalReducer(state = initialState, { type, payload }) {
    switch (type) {
        case OpenLoginPopUp:
            return { ...state, isLogInPopupOpen: true };
        case CloseLoginPopUp:
            return { ...state, isLogInPopupOpen: false };
        case OPEN_OTHER_MODAL:
            return { ...state, isOtherModalOpen: true };
        case CLOSE_OTHER_MODAL:
            return { ...state, isOtherModalOpen: false };
        case NEW_APP_LAUNCH:
            return { ...initialState }
        default:
            return state;

    }
}
