export let isAuth = sessionStorage.getItem("auth");

const currentProtocol = window.location.href;
const urlObject = new URL(currentProtocol)
const portNo = urlObject.port;

export let docAuth = "https://api.growthgrids.com/bd_growthgrids/doc_file/";
 export let docAuthInt = "https://api.growthgrids.com/bd_growthgrids/int_tender_docs"
export let ClientId = "";
export let baseUrl = "";
export let currencyIcon = "";
export let baseUrlSitemap = "";
export let bidBaseUrl = "https://web.growthgrids.com/api/user";

export let chat_socket_url = "https://demo.growthgrids.com";
export let socketBaseUrl = "https://demo.growthgrids.com";
export let docurlchat = "https://web.growthgrids.com/";
// export let statBaseUrl = 'http://202.131.122.246:3013/api/user'
export let statBaseUrl = "https://testing.growthgrids.com/api/user";
export let profileImageAuth = "";
export let LinkUrl = ''
if (currentProtocol.includes("http://localhost")) {
  LinkUrl = `http://localhost:${portNo}`
  baseUrl = process.env.REACT_APP_DEV_API_BASE_URL;
  baseUrlSitemap = process.env.REACT_APP_DEV_API_BASE_URL_SITEMAP;
  currencyIcon = process.env.REACT_APP_DEV_API_CURRENCY_ICON_URL
  // profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`;
  profileImageAuth=`https://staging.cegtechno.com/bd_growthgrids_new/public/uploads/profile/`
  ClientId =
    "785835417311-1gqg8gm9eplguflcme7prbh2m25dkmdf.apps.googleusercontent.com";
  bidBaseUrl = "https://web.growthgrids.com/api/user";
  docurlchat = "https://web.growthgrids.com/";
  socketBaseUrl = "https://demo.growthgrids.com";

} else if (currentProtocol.includes("https://staging.growthgrids.com")) {
  LinkUrl = 'https://staging.growthgrids.com'
  baseUrl = process.env.REACT_APP_STAG_API_BASE_URL;
  baseUrlSitemap = process.env.REACT_APP_STAG_API_BASE_URL_SITEMAP;
  currencyIcon = process.env.REACT_APP_DEV_API_CURRENCY_ICON_URL
  docAuth = "https://api.growthgrids.com/bd_growthgrids/doc_file/";
  // profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`;
  profileImageAuth=`https://staging.cegtechno.com/bd_growthgrids_new/public/uploads/profile/`

  ClientId =
    "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com";
  bidBaseUrl = "https://web.growthgrids.com/api/user";
  docurlchat = "https://web.growthgrids.com/";
  socketBaseUrl = "https://demo.growthgrids.com";
} else if (currentProtocol.includes("https://apps.growthgrids.com")) {
  LinkUrl = 'https://apps.growthgrids.com'
  baseUrl = process.env.REACT_APP_PROD_API_BASE_URL;
  baseUrlSitemap = process.env.REACT_APP_PROD_API_BASE_URL_SITEMAP;
  currencyIcon = process.env.REACT_APP_DEV_API_CURRENCY_ICON_URL
  docAuth = "https://api.growthgrids.com/bd_growthgrids/doc_file/";
  profileImageAuth = `https://api.growthgrids.com/bd_growthgrids/public/uploads/profile/`;
  ClientId =
    "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com";
  bidBaseUrl = "https://api2.growthgrids.com/api/user";
  docurlchat = "https://api2.growthgrids.com/";
  socketBaseUrl = "https://socket.growthgrids.com";
} else if (currentProtocol.includes("http://apps.growthgrids.com")) {
  LinkUrl = 'http://apps.growthgrids.com'
  baseUrl = process.env.REACT_APP_PROD_API_BASE_URL;
  baseUrlSitemap = process.env.REACT_APP_PROD_API_BASE_URL_SITEMAP;
  currencyIcon = process.env.REACT_APP_DEV_API_CURRENCY_ICON_URL
  docAuth = "https://api.growthgrids.com/bd_growthgrids/doc_file/";
  profileImageAuth = `https://api.growthgrids.com/bd_growthgrids/public/uploads/profile/`;
  ClientId =
    "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com";
  bidBaseUrl = "https://api2.growthgrids.com/api/user";
  docurlchat = "https://api2.growthgrids.com/";
  socketBaseUrl = "https://socket.growthgrids.com";
} else {
  LinkUrl = 'https://api.growthgrids.com'
  baseUrl = process.env.REACT_APP_DEV_API_BASE_URL;
  baseUrlSitemap = process.env.REACT_APP_DEV_API_BASE_URL_SITEMAP;
  currencyIcon = process.env.REACT_APP_DEV_API_CURRENCY_ICON_URL
  docAuth = "https://api.growthgrids.com/bd_growthgrids/doc_file/";
  // profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`;
  profileImageAuth=`https://staging.cegtechno.com/bd_growthgrids_new/public/uploads/profile/`

  ClientId =
    "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com";
  bidBaseUrl = "https://api2.growthgrids.com/api/user";
  docurlchat = "https://api2.growthgrids.com/";
  socketBaseUrl = "https://socket.growthgrids.com";

}
