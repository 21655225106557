// @ts-nocheck
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import { userDataReducer } from './reducers/common/auth';
import { userInfoReducer } from './reducers/common/userInfoReducer';
import { filterCompanyReducer } from './reducers/tendergrid/FilterCompanyReducer';
import { seoRequestValueReducer, dropdownValReducer, dynamicMenuReducer, bidDropdownValReducer, statDropdownValReducer } from './reducers/common/DropdownReducer';
import CycleFilterReducer from './reducers/bidgrid/cycleFilterReducer';
import { checkoutReducer } from './reducers/tendergrid/checkoutReducer';
import socketReducer from './reducers/bidgrid/socketReducer';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import MisFilterReducer from './reducers/bidgrid/misFilterReducer';
import { companybalanceReducer } from './reducers/statgrid/ComapnyBalanceReducer';
import navigationDataReducer, { BidMenuReducer } from './reducers/bidgrid/navigationDataReducer';
import TrashFilterReducer from './reducers/bidgrid/trashFilterReducer';
import StatFilterReducer from './reducers/statgrid/statFilterReducer';
import { StatCheckoutReducer } from './reducers/statgrid/statCheckoutReducer';
import BlogReducer from './reducers/tendergrid/BlogReducer';
import filterReducer from './reducers/tendergrid/filterReducer';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'
import LoginModalReducer from './reducers/tendergrid/LoginModalReducer'
import currencyReducer from './reducers/common/currencyReducer';
const appReducer = combineReducers({
  loginData: userDataReducer,
  userDetails: userInfoReducer,
  cycleFilter: CycleFilterReducer,
  companyStatBalance: companybalanceReducer,
  misFilter: MisFilterReducer,
  trashFilter: TrashFilterReducer,
  companyStatBalance: companybalanceReducer,
  companyData: filterCompanyReducer,
  dropdownCalVal: dropdownValReducer,
  dynamicMenuVal: dynamicMenuReducer,
  bidDropdownCalVal: bidDropdownValReducer,
  statDropdownCalVal: statDropdownValReducer,
  nettingValues: filterReducer,
  checkoutData: checkoutReducer,
  statCheckoutData: StatCheckoutReducer,
  seoValues: seoRequestValueReducer,
  socket: socketReducer,
  navigationData: navigationDataReducer,
  StatFilter: StatFilterReducer,
  blogdata: BlogReducer,
  bidMenuVal: BidMenuReducer,
  logInPopUp: LoginModalReducer,
  currency: currencyReducer
});


const persistConfig = {
  key: 'empersistReducer',
  version: 1,
  storage: createIdbStorage({ name: 'myApp' }),
  whitelist: [
    'dynamicMenuVal',
    'seoValues',
    "dropdownCalVal",
    "bidDropdownCalVal",
    'loginData',
    "cycleFilter",
    "misFilter",
    "trashFilter",
    "userDetails",
    "companyData",
    "subscribeData",
    "purchasedData",
    "wishlistData",
    "checkoutData",
    "statDropdownCalVal",
    "companyStatBalance",
    "StatFilter",
    "nettingValues",
    "statCheckoutData",
    "blogdata",
    "bidMenuVal",
    "logInPopUp",
    "currency"
  ]
};

const syncMiddleware = createStateSyncMiddleware({
  whitelist: [
    'loginData',
    "userDetails",
    'dynamicMenuVal',
    'seoValues',
    "dropdownCalVal",
    "bidDropdownCalVal",
    'loginData',
    "cycleFilter",
    "misFilter",
    "trashFilter",
    "userDetails",
    "checkoutData",
    "nettingValues",
    "bidMenuVal",
    "logInPopUp",
    "currency"
  ],
  broadcastChannelOption: {
    type: 'localstorage', // Use localStorage for state synchronization between tabs
    persistence: 'permanent', // Store the state permanently in localStorage
  },
});




const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, syncMiddleware)));
initMessageListener(store);

const persistor = persistStore(store);

export { persistor, store };