import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant"
import {
    DROPDOWN_REQUEST,
    DROPDOWN_FAILED,
    DROPDOWN_SUCCESS,
    SEO_REQUEST,
    SEO_FAILED,
    SEO_SUCCESS,
    DYNAMIC_MENU_REQUEST,
    DYNAMIC_MENU_SUCCESS,
    DYNAMIC_MENU_FAILED,
    BID_DROPDOWN_REQUEST,
    BID_CLIENT_SUCCESS,
    BID_DROPDOWN_FAILED,
    BID_CLIENT_FUNDING_AGENCY_SUCCESS,
    BID_COUNTRY_SUCCESS,
    BID_SECTOR_SUCCESS,
    BID_DROPDOWN_RESETALL,
    DROPDOWN_RESETALL,
    DROPDOWN_MENU_RESET,
    STAT_DROPDOWN_REQUEST,
    STAT_CLIENT_SUCCESS,
    STAT_DROPDOWN_FAILED,
    STAT_FUNDING_SUCCESS,
    STAT_TENDER_CAT_SUCCESS,
    STAT_DROPDOWN_RESETALL,
    STAT_TENDER_COUNTRY_SUCCESS,
    STAT_TENDER_STATE_SUCCESS,
    STAT_SECTOR_SUCCESS,
} from "../../constants/common/DropdownConstant"

export const dropdownValReducer = (state = { dropdownValues: {}, }, { type, payload }) => {
    switch (type) {
        case DROPDOWN_REQUEST:
            return { ...state }
        case DROPDOWN_SUCCESS:
            return { ...state, dropdownValues: payload }
        case DROPDOWN_RESETALL:
            return { ...state, dropdownValues: payload }
        case DROPDOWN_FAILED:
            return { ...state, error: payload }
        case NEW_APP_LAUNCH:
            return { dropdownValues: {} }
        default: return state
    }
}

export const dynamicMenuReducer = (state = { dynamicMenu: [], }, { type, payload }) => {
    switch (type) {
        case DYNAMIC_MENU_REQUEST:
            return { ...state }
        case DYNAMIC_MENU_SUCCESS:
            return { ...state, dynamicMenu: payload }
        case DROPDOWN_MENU_RESET:
            return { ...state, dynamicMenu: payload }
        case DYNAMIC_MENU_FAILED:
            return { ...state, error: payload }
        case NEW_APP_LAUNCH:
            return { dynamicMenu: [] }
        default: return state
    }
}

const initialState = {
    seoValue: {
        page_title: '',
        meta_desc: '',
        meta_key: ''
    }
}


export const seoRequestValueReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEO_REQUEST:
            return { ...state }
        case SEO_SUCCESS:
            return { ...state, seoValue: { ...state.seoValue, ...payload } };
        case SEO_FAILED:
            return { ...state, error: payload };
        case NEW_APP_LAUNCH:
            return { ...initialState }
        default:
            return state;
    }
};


export const statDropdownValReducer = (state = {
    StatClient: [],
    StatFunding: [],
    StatTenderCat: [],
    StatColuntry: [],
    StatState: [],
    StatSector: []
}, { type, payload }) => {
    switch (type) {
        case STAT_DROPDOWN_REQUEST:
            return { ...state }
        case STAT_CLIENT_SUCCESS:
            return { ...state, StatClient: payload }
        case STAT_FUNDING_SUCCESS:
            return { ...state, StatFunding: payload }
        case STAT_TENDER_CAT_SUCCESS:
            return { ...state, StatTenderCat: payload }
        case STAT_TENDER_COUNTRY_SUCCESS:
            return { ...state, StatColuntry: payload }
        case STAT_TENDER_STATE_SUCCESS:
            return { ...state, StatState: payload }
        case STAT_SECTOR_SUCCESS:
            return { ...state, StatSector: payload }
        case STAT_DROPDOWN_FAILED:
            return { ...state, error: payload }
        case STAT_DROPDOWN_RESETALL:
            return {
                ...state,
                StatClient: [],
                StatFunding: [],
                StatTenderCat: [],
                StatColuntry: [],
                StatState: [],
                StatSector: []
            }
        case NEW_APP_LAUNCH:
            return {
                StatClient: [],
                StatFunding: [],
                StatTenderCat: [],
                StatColuntry: [],
                StatState: [],
                StatSector: []
            }
        default:
            return state
    }
}


export const bidDropdownValReducer = (state = {
    BidClient: [],
    BidFundingClientAgency: [],
    BidCountry: [],
    BidSector: [],

}, { type, payload }) => {
    switch (type) {
        case BID_DROPDOWN_REQUEST:
            return { ...state }
        case BID_CLIENT_SUCCESS:
            return { ...state, BidClient: payload }
        case BID_CLIENT_FUNDING_AGENCY_SUCCESS:
            return { ...state, BidFundingClientAgency: payload }
        case BID_COUNTRY_SUCCESS:
            return { ...state, BidCountry: payload }
        case BID_SECTOR_SUCCESS:
            return { ...state, BidSector: payload }
        case BID_DROPDOWN_FAILED:
            return { ...state, error: payload }
        case BID_DROPDOWN_RESETALL:
            return {
                ...state,
                BidClient: [],
                BidFundingClientAgency: [],
                BidCountry: [],
                BidState: [],
                BidSector: [],
            }
        case NEW_APP_LAUNCH:
            return {
                BidClient: [],
                BidFundingClientAgency: [],
                BidCountry: [],
                BidState: [],
                BidSector: [],
            }
        default:
            return state
    }
}


