import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant"
import { CHECKOUT_INFO_SUCCESS, CHECKOUT_WALLET_UPDATE_SUCCESS, CHECKOUT_INFO_REQUEST, CHECKOUT_INFO_FAILURE } from "Redux/constants/tendergrid/checkoutConstant"
const initialState = {
    walletBalance: 0,
    subscriptionPlan: {},
    tenderDetail: {},
    type: 'wallet'
}

export const checkoutReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CHECKOUT_INFO_REQUEST:
            return { ...state }
        case CHECKOUT_INFO_SUCCESS:
            return {
                ...state,
                walletBalance: payload.walletBalance,
                subscriptionPlan: payload.subscriptionPlan,
                tenderDetail: payload.tenderDetail,
                type: payload.type
            }
        case CHECKOUT_WALLET_UPDATE_SUCCESS:
            return {
                ...state,
                walletBalance: payload.walletBalance,
            }
        case CHECKOUT_INFO_FAILURE:
            return { ...state, error: payload }
        case NEW_APP_LAUNCH:
            return { ...initialState }
        default: return state
    }
}