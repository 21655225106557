import { APIService } from "../../APIService";
import { baseUrl, isAuth } from "../../../utils/configurable";
import { authHeader } from '../../../helper/authHeader'
import axios from "axios";

const login = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data"
    }
    try {
        data.append('logout_other_devices', true)
        const response = await APIService.post(`${baseUrl}/login`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const socialLogin = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/social_login`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


const socialRegistration = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/social_registration`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const userRegisteration = async (data) => {
 
    const headers = {
        'Content-Type': "multipart/form-data",
    }

    try {
        const response = await APIService.post(`${baseUrl}/registration`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const forgotPassword = async (data) => {
    const header = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/forgotpassword`, data, { header })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}


const sendOtpRequest = async (data) => {
    const header = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/send_otp`, data, { header })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const verifyOtpRequest = async (data) => {
    const header = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/verify_otp`, data, { header })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}

const MobileverifyOtpRequest = async (data) => {
    const header = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/regtime_mo_otp_check`, data, { header })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}

const sendMobileOtp = async (data) => {
    const header = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/regtime_mo_verification`, data, { header })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getValidEmailAddressCheck = async (data) => {
    const header = {
        'Content-Type': "multipart/form-data"
    }
    try {
        const response = await APIService.post(`${baseUrl}/check_email_exist`, data, { header })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const addUserByAdmin = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'auth-token': localStorage.getItem('auth')
    }

    try {
        const response = await APIService.post(`${baseUrl}/create_user_byParent_user
`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getUsersAddedByAdmin = async () => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'auth-token': localStorage.getItem('auth')
    }
    try {
        const response = await APIService.get(`${baseUrl}/get_all_users`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const editUserByAdmin = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'auth-token': localStorage.getItem('auth')
    }

    try {
        const response = await APIService.post(`${baseUrl}/update_child_userData
`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const deleteUserByAdmin = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'auth-token': localStorage.getItem('auth')
    }

    try {
        const response = await APIService.post(`${baseUrl}/child_user_activate_deactivate
`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const editUserPasswordByAdmin = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'auth-token': localStorage.getItem('auth')
    }

    try {
        const response = await APIService.post(`${baseUrl}/changechildpassword
`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

export const UserAuthServices = {
    login,
    userRegisteration,
    socialLogin,
    forgotPassword,
    sendOtpRequest,
    verifyOtpRequest,
    MobileverifyOtpRequest,
    sendMobileOtp,
    getValidEmailAddressCheck,
    socialRegistration,
    addUserByAdmin,
    getUsersAddedByAdmin,
    editUserByAdmin,
    deleteUserByAdmin,
    editUserPasswordByAdmin

}