import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";
const initialState = {
    currency: null,    // Current selected currency
    ipAddress: null,   // Last known IP address
    isIpBased: true,   // Tracks whether the currency is IP-based or user-changed
    loading: false
};

const currencyReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_CURRENCY':
            return {
                ...state,
                currency: action.payload.currency,
                isIpBased: action.payload.isIpBased,
            };
        case 'SET_IP_ADDRESS':
            return {
                ...state,
                ipAddress: action.payload,
            };
        case 'SET_IP_LOADING':
            return {
                ...state,
                loading: action.payload,
            };

        case NEW_APP_LAUNCH:
            return { ...initialState }
        default:
            return state;
    }
};

export default currencyReducer;