// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserInfoServices } from "../Services/common/user/userinfo";
import { userInfoAction } from '../Redux/actions/common/userInfoAction';
import { CountService } from "../Services/tendergrid/tendercount/tendercount";
import { DropdownValuesServices } from '../Services/common/dropdown/dropdownValues'
import { useDispatch } from "react-redux";
import { dropdownValAction, dynamicMenuAction, seoValuesAction } from '../Redux/actions/common/DropdownAction';
import { NavBarMenu } from "Services/common/navbar/NavBarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { ClothesGloves } from "@icon-park/react";

const initialState = {
    country: [],
    state: [],
    sector: [],
    client: [],
    fundingAgency: [],
    fin_year: [],
    tndr_category_type: [],
    clientByState: [],
    webSiteData: [],
    irepsClient: []
}
const DropdownValues = () => {
    const objDetiail = new FormData()
    const location = useLocation()
    const val = location?.pathname;
    const str = val.replace('/', '')
    const dispatch = useDispatch()
    const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
    const { seoValue } = useSelector((state) => state.seoValues)
    const { userData } = useSelector((state) => state.loginData)
    const { dropdownValues } = useSelector((state) => state.dropdownCalVal)
    const [data, setData] = useState(dropdownValues)
    const navigate = useNavigate()


    useEffect(() => {
        let url = location.pathname;
        if (url.endsWith('/')) {
            url = url.replace(/\/+/g, '');
            navigate(url)
        }
    }, [location]);

    const userServiceRes = () => {

        try {
            UserInfoServices.requestUser()
                .then((response) => {
                    if (response?.data?.status === 1) {
                        dispatch(userInfoAction(response.data.data))
                    }
                    else if (response?.validation_error) {
                        localStorage.clear();
                        window.location.reload();
                        localStorage.setItem("auth", "");
                    }
                })
        }
        catch {
            console.log('error')
        }
    }


    const getNavBarMenuItem = () => {

        try {
            NavBarMenu?.getNavBar().then((response) => {
                let data = Object?.values(response?.data?.data)
                dispatch(dynamicMenuAction(data))

            });
        } catch (error) {
            console.log(error);
        }
    };


    const fetchAllCOuntryOnly = (array) => {
        let countries = [];
        array.forEach((location) => {
            if (location?.hasOwnProperty('children') && location?.children?.length > 0) {

                location.children.forEach((region) => {

                    if (region.countries.length) {

                        region.countries.forEach((count) => {
                            const country = {
                                id: `${count.uniques_country_id}-${count.country_id}_${location.fld_id}-${region.fld_id}`,
                                fld_id: count.country_id,
                                label: count.country_name,
                                uuid: `${location.fld_id}-${region.fld_id}`,
                                image_name: count.image_name,
                                tender_count_archive: count.tender_count_archive,
                                tender_count_live: count.tender_count_live,
                                isGeographical: true
                            };
                            countries.push(country)
                        })
                    }
                })
            }
            else if (location.hasOwnProperty('countries') && location.countries.length > 0) {
                location.countries.forEach((count) => {
                    const country = {
                        id: `${count.uniques_country_id}-${count.country_id}_${location.parent_id}-${location.fld_id}`,
                        fld_id: count.country_id,
                        label: count.country_name,
                        uuid: `${location.parent_id}-${location.fld_id}`,
                        image_name: count.image_name,
                        tender_count_archive: count.tender_count_archive,
                        tender_count_live: count.tender_count_live,
                        isGeographical: false
                    };
                    countries.push(country)
                })
            }

        });

        return countries
    }

    const getCountry = async () => {

        if (dropdownValues?.hasOwnProperty("country")) {
            return;
        }
        try {
            const formData = new FormData()
            await DropdownValuesServices.getCountryWithContinantData(formData).then(res => {
                if (res?.data?.data?.length > 0) {
                    const country = fetchAllCOuntryOnly(res.data.data)
                    setData((previous) => ({
                        ...previous,
                        country: country
                    }));
                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    const getStates = async () => {
        if (dropdownValues?.hasOwnProperty("state")) {
            return;
        }
        objDetiail.append('country_id', 1);
        try {
            const res_state = await DropdownValuesServices.getStateDataByCountryId(objDetiail);
            if (res_state?.data?.status === 1) {
                setData((previous) => ({
                    ...previous,
                    state: res_state?.data?.data?.filter(val => val?.state_name !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getClient = async () => {
        if (dropdownValues?.hasOwnProperty("client")) {
            return;
        }
        try {
            const res_client = await DropdownValuesServices.getClientList();
            if (res_client?.data?.status === 1) {
                setData((previous) => ({
                    ...previous,
                    client: res_client?.data?.data?.filter(val => val?.client_name !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getClientByState = async (val) => {
        if (dropdownValues?.hasOwnProperty("clientByState")) {
            return;
        }
        try {
            const PayloadData = new FormData();
            PayloadData.append('state_id', val || "");
            const res_client = await DropdownValuesServices.getclientListState(PayloadData);

            if (res_client?.data?.status === 1) {
                setData((previous) => ({
                    ...previous,
                    clientByState: res_client?.data?.data?.filter(val => val?.client_name !== 'NA')

                }));
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getSector = async () => {
        if (dropdownValues?.hasOwnProperty("sector")) {
            return;
        }
        try {
            const res_sector = await DropdownValuesServices.getTenderCategory();
            if (res_sector?.data?.status === 1) {
                setData((previous) => ({
                    ...previous,
                    sector: res_sector?.data?.data?.filter(val => val?.sectName !== 'NA')

                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    // const getWebsite = async () => {
    //     const currentYear = new Date().getFullYear();
    //     const previousYear = currentYear - 1
    //     const finYearString = `${previousYear}-${currentYear}`;
    //     const data = {
    //         fin_year: finYearString,
    //     };
    //     const payload = new FormData();
    //     payload.append('fin_year', data?.fin_year);
    //     try {
    //         const web_res = await DropdownValuesServices?.getWebsiteList();
    //         const web_tender_count = await CountService.getwebsitecount(payload);

    //         if (web_res?.data?.status === 1 && web_tender_count?.data?.status === 1) {

    //             const webCount = web_tender_count?.data?.data;
    //             const updateWebData = web_res?.data?.data.map(webSiteData => ({
    //                 ...webSiteData,
    //                 web_tender_count: parseInt(webCount[webSiteData.fld_id]) || 0
    //             }));

    //             setData(prevState => ({
    //                 ...prevState,
    //                 webSiteData: updateWebData?.filter(val => val?.url !== 'NA')
    //             }));
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };



    const getAgency = () => {
        if (dropdownValues?.hasOwnProperty("fundingAgency")) {
            return;
        }
        try {
            DropdownValuesServices.getFundingList().then(res => {
                if (res?.data?.status === 1) {
                    setData((previous) => ({
                        ...previous,
                        fundingAgency: res?.data?.data?.filter(val => val?.funding_org !== 'NA')

                    }));
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getTendercategoryType = () => {
        if (dropdownValues?.hasOwnProperty("tndr_category_type")) {
            return;
        }
        try {
            DropdownValuesServices.getTenderType().then(res => {
                if (res?.data?.status === 1) {
                    setData((previous) => ({
                        ...previous,
                        tndr_category_type: res?.data?.data.filter(val => val?.category_phase !== 'NA')
                    }));
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    const getIrepsAuthority = () => {
        if (dropdownValues?.hasOwnProperty("irepsClient")) {
            return;
        }
        try {
            DropdownValuesServices.getIrepsDataList().then(res => {
                if (res?.data?.status === 1) {
                    setData((previous) => ({
                        ...previous,
                        irepsClient: res?.data?.data.filter(val => val?.client_name !== 'NA')
                    }));
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    // const setFirebaseNotification = () => {
    //     const formData = new FormData()
    //     formData.append('user_id', userData?.user_data?.loginid)
    //     formData.append('token', fireBase_token)
    //     if (fireBase_token) {
    //         try {
    //             firebaseServices.webPushNotification(formData).then(res => {
    //                 console.log("firebase response", res)
    //             })
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    // }


    const windowReloadORdirectAccess = () => {
        const pathname = location.pathname;
        const parts = pathname.split('/').filter(part => part !== '');
        let tender_id = parts[parts?.length - 1]
        let updatedstr;
        if (parts?.length <= 2) {
            updatedstr = parts.join('/');
        } else {
            updatedstr = parts.slice(0, 2).join('/')
        }

        let type = parts[2]
        let type_name = decodeURIComponent(parts[3])
        let matchedItem = dynamicMenu?.find(item => item.action_url === updatedstr);
        if (!matchedItem) {
            dynamicMenu?.forEach(item => {
                if (item.child) {
                    const childMatch = item.child.find(childItem => childItem.action_url === updatedstr);
                    if (childMatch) {
                        matchedItem = childMatch;
                        return;
                    }
                }
            });
        }
        const id = matchedItem ? matchedItem.fld_id : null;
        if (id && tender_id) {
            dispatch(seoValuesAction(id, tender_id))
        }
    }
    useEffect(() => {
        // Function to handle URL changes
        const handleUrlChange = () => {
            var currentUrl = window.location.href;
            if (currentUrl.includes("tendergrid")) {
                document.body.className = "tender-grid-module";
            } else if (currentUrl.includes("bidgrid")) {
                if (str === "bidgrid/chatgrid") {
                    document.body.className = "bid-grid-module bd_chat_module";
                } else {
                    document.body.className = "bid-grid-module";
                }

            } else {
                document.body.className = "tender-grid-module";
            }
        };

        // Initial setup
        handleUrlChange();

    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname.includes('authority') || location.pathname.includes('state') || location.pathname.includes('sector') || location.pathname.includes('country')) {
            return
        } else {
            windowReloadORdirectAccess()
        }
    }, [location.pathname])

    useEffect(() => {
        document.title = seoValue?.seo_val ? seoValue?.seo_val + " " + "Tenders" : seoValue?.page_title;

        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', seoValue?.seo_val ? seoValue?.seo_val + " " + "Tenders" + " " + seoValue?.meta_desc : seoValue?.meta_desc);
        }
        const metaTagKey = document.querySelector('meta[name="keywords"]');
        if (metaTagKey) {
            metaTagKey.setAttribute('content', seoValue?.seo_val ? seoValue?.seo_val + " " + "Tenders" + " " + seoValue?.meta_key : seoValue?.meta_key);
        }
    }, [seoValue]);


    useEffect(() => {

        if (window.location.href.includes("bidgrid")) {
            return
        } else {
            if (localStorage.getItem('auth') !== '') {
                userServiceRes()
            }

            getCountry()
            getStates()
            getSector()
            getClient()
            getAgency()
            getNavBarMenuItem()
            getTendercategoryType()
            getClientByState()
            getIrepsAuthority()
            // setFirebaseNotification()
        }

    }, [userData])




    useEffect(() => {
        dispatch(dropdownValAction(data))
    }, [data])

    return;
}

export default DropdownValues



