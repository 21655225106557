import { store } from "../Redux/store"
import { userLogoutAction, userStatLogoutAction } from "../Redux/actions/common/authAction";
import _ from "lodash";
import has from "lodash/has"

export const verifyAuth = response => {
    // console.log("auth.js======================");
    if (has(response.data, "validation_error")) {
        store.dispatch(userLogoutAction())
    }
}





export const verifyStatAuth = response => {
    // if (has(response.data, "validation_error"))
         {
        store.dispatch(userStatLogoutAction)
    }
}