import { APIService } from "../../APIService";
import { baseUrl } from "../../../utils/configurable";
import { verifyAuth } from "../../../utils/auth";
import { authHeader } from '../../../helper/authHeader'


const requestUser = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/UserDetailsByID`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


const changePassword = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/changepassword`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}


const getNewsLetter = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/newsletter_email`, data)
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getSectorKeyword = async () => {
    const headers = authHeader()

    try {
        const response = await APIService.get(`${baseUrl}/sector_keyword_userwise`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getSectorKeywordRequest = async (data) => {
    const headers = authHeader()

    try {
        const response = await APIService.post(`${baseUrl}/sector_keyword_request`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const profileDetails = async () => {
    const headers = authHeader()
    try {
        const response = await APIService.get(`${baseUrl}/user_transaction_history`, { headers });
        verifyAuth(response)
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const updateProfile = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/Userprofile_update`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const UserSubscription = async (data) => {
        // const headers = authHeader()

    try {
        const response = await APIService.post(`${baseUrl}/subscriptionlist`, data);
        return response.data
    }
    catch (error_msg) {
        return error_msg
    }
}


const userContactDetails = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/contact_us`, data, { headers });
        return response.data
    }
    catch (error_msg) {
        return error_msg.response
    }
}
const addKyc = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/addkycdetails`, data, { headers });
        return response.data
    }
    catch (error_msg) {
        return error_msg
    }
}
const userContact = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/user_contact_details`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const couponList = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data", 'auth-token':localStorage.getItem('auth') }

    try {
        const response = await APIService.get(`${baseUrl}/active_coupon_api`, {headers})
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}

const getUserConatctDetails = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/user_contect_notification`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const UserWallet = async () => {
    const headers = authHeader()
    try {
        const response = await APIService.get(`${baseUrl}/user_wallet_balance`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }

}


const UserWalletAdd = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/add_wallet_balance`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }

}
const CouponCheck = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/coupon_check_valid`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }

}

const addCoupon = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/coupon_no_user_update`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }

}

const updateSubs = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/User_upgrade_subs`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }

}
const GetFreeTrialInfo = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/free_trial`, data, { headers })
        return response
    } catch (error_msg) {
        return error_msg
    }
}


const getFooterSeo = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/get_footer_seo`, data)
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
export const UserInfoServices = {
    requestUser,
    changePassword,
    getNewsLetter,
    getSectorKeyword,
    getSectorKeywordRequest,
    profileDetails,
    updateProfile,
    UserSubscription,
    couponList,
    userContact,
    addKyc,
    userContactDetails,
    getUserConatctDetails,
    UserWallet,
    CouponCheck,
    addCoupon,
    updateSubs,
    UserWalletAdd,
    GetFreeTrialInfo,
    getFooterSeo
}