import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";
import {
    FILTER_REQUEST,
    FILTER_SUCCESS,
    FILTER_FAILED,
    IP_CHANGED,
} from "Redux/constants/tendergrid/FilterConstants"

export const DEFAULT_LIMIT = 25;
export const DEFAULT_PAGE = 0;

export const createInitialState = () => Object.freeze({
    state_id: [],
    tnd_ref_id: "",
    tnd_id: '',
    tnd_govt_id: '',
    client_id: [],
    tender_keyword: "",
    tender_docs: "",
    sector_id: [],
    country_id: [],
    country_id_pol: [],
    region_id: [],
    political_ids: [],
    continent_id: [],
    funding_agency_id: [],
    from_date: "",
    to_date: "",
    latest_activity: '',
    published_date: "",
    close_exp_date: "",
    estm_value: "",
    estm_value_emd: "",
    pubdate_cust_from_date: "",
    pubdate_cust_to_date: "",
    expdate_cust_from_date: "",
    expdate_cust_to_date: "",
    sort_key: "",
    sort_val: "",
    limit: DEFAULT_LIMIT,
    page_number: DEFAULT_PAGE,
    tndr_category_type: [],
    wishlist_category_id: '',
    tndr_not_specified_amt: "tndr_not_specified_amt",
    tndr_not_specified_emd: "tndr_not_specified_emd",
    currency_id: '',
    minTenderEmdVal: '',
    maxTenderEmdVal: '',
    minTenderVal: '',
    maxTenderVal: '',
    live_tender_range: '',
    live_tender_range_from_date: "",
    live_tender_range_to_date: "",
    update_tender_range: "",
    update_tender_range_from_date: "",
    update_tender_range_to_date: "",
    list_type: 'all',
    is_ip_changed: false,
    // tempId: ''
});

export const nettingValues = {
    tenderList: createInitialState(),
    subscribe: createInitialState(),
    purchased: createInitialState(),
    wishlist: createInitialState(),
    ireps: createInitialState(),
    gem: createInitialState(),
    pmgsy: createInitialState(),
    eproc: createInitialState()
};

const filterReducer = (state = nettingValues, { type, payload, key, status }) => {

    switch (type) {
        case FILTER_REQUEST:
            return { ...state };

        case FILTER_SUCCESS:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...payload,
                },
            };
        case IP_CHANGED:

            return {
                ...state,
                [key]: {
                    ...state[key],
                    is_ip_changed: payload,
                },
            };

        case FILTER_FAILED:
            return { ...state };
        case NEW_APP_LAUNCH:
            return { ...nettingValues }
        default:
            return state;
    }
};

export default filterReducer;