// @ts-nocheck
import {
    FILTER_INACTION_FAILED,
    FILTER_INACTION_REQUEST,
    FILTER_UPDATE_ALL_KEYS_OBJECTS,
    FILTER_UPDATE_STATE_VAL,
    FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS,
    FILTER_RESET_ARR_KEYS,
    FILTER_RESET_INDIVIDUAL_ARR_OBJECTS,
    FILTER_IS_UPDATE_RESET,
    FILTER_IS_UPDATE_RESET_TRUE,

} from "../../constants/bidgrid/bidgridConstants";
import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";
let filterValObj = {
    limit: 25,
    page_number: 1,
    country_id: '',
    state_id: '',
    sector_id: '',
    tender_keyword: '',
    from_date: '',
    to_date: '',
    client_id: '',
    currency_id: '',
    funding_id: '',
    tender_status: '',
    published_date: '',
    close_exp_date: '',
    estm_value: '',
    estm_value_emd: '',
    pubdate_cust_from_date: '',
    pubdate_cust_to_date: '',
    expdate_cust_from_date: '',
    expdate_cust_to_date: '',
    amnt_custrange_operator: '',
    amnt_custrange_amount: '',
    custrange_denomination: '',
    amnt_custrange_operator_emd: '',
    amnt_custrange_amount_emd: '',
    custrange_denomination_emd: '',
    cycle_id: '',
    // tender_result_id: '',
    tender_activity_status: '',
    located: '',
    orderSerial: '0',
    sort_key: '',
    sort_val: '',
    ping_users: '',
    service_provider: '',
    generated_tender_id: '',

}

const convertObjectToArr = (val) => {
    const chipsArr = Object.entries(val).map(([key, value]) => ({ key, value }));
    return chipsArr;
}

export const initialState = {
    filterValues: filterValObj,
    filetrChips: convertObjectToArr(filterValObj),
    // stateValue: [],
    isUpdate: false,
    error: ''
};

const CycleFilterReducer = (state = initialState, { type, payload }) => {
    // debugger;
    switch (type) {

        case FILTER_INACTION_REQUEST:
            return { ...state };

        case FILTER_UPDATE_ALL_KEYS_OBJECTS:
            // debugger;
            const updatedFilterValues = { ...payload };
            return { ...state, filterValues: updatedFilterValues, filetrChips: convertObjectToArr(updatedFilterValues), isUpdate: true };

        // CAN BE USED AS APPLY TEMPLATE TOO
        case FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS:
            const updatedFilterValuesIndividual = { ...state.filterValues, ...payload };
            const updatedFilterChipsIndividual = state.filetrChips.map(chip => {

                if (payload[chip.key] !== undefined) {
                    updatedFilterValuesIndividual[chip.key] = payload[chip.key];
                    return { key: chip.key, value: payload[chip.key] };
                }
                return chip;
            });

            return { ...state, filterValues: updatedFilterValuesIndividual, filetrChips: updatedFilterChipsIndividual, isUpdate: true };

        case FILTER_RESET_INDIVIDUAL_ARR_OBJECTS:

            const resetKey = payload;

            let updatedFilterValuesReset = { ...state.filterValues };
            resetKey?.map((item) => {
                return updatedFilterValuesReset[item] = ''
            })
            return { ...state, filterValues: updatedFilterValuesReset, filetrChips: convertObjectToArr(updatedFilterValuesReset), isUpdate: true };

        case FILTER_RESET_ARR_KEYS:
            return { ...state, filterValues: filterValObj, filetrChips: convertObjectToArr(filterValObj), isUpdate: true };

        case FILTER_IS_UPDATE_RESET:
            return { ...state, isUpdate: false };

        case FILTER_IS_UPDATE_RESET_TRUE:
            return { ...state, isUpdate: true };

        case FILTER_INACTION_FAILED:
            return { ...state, error: payload, isUpdate: false };

        case NEW_APP_LAUNCH:
            return { ...initialState }

        default:
            return state;
    }
};





export default CycleFilterReducer;

