
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,

    BIDGRID_DATA_REQUEST,
    BIDGRID_DATA_SUCCESS,
    BIDGRID_DATA_FAILED,
    LOGOUT_BID,

    STATGRID_DATA_REQUEST,
    STATGRID_DATA_SUCCESS,
    STATGRID_DATA_FAILED,
    LOGOUT_STAT,
    NEW_APP_LAUNCH
} from "../../constants/common/authConstant";
const initialState = {
    userData: {},
    bidgridData: {},
    statgridData: {}
};

export const userDataReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case LOGIN_REQUEST:
            return { ...state };
        case LOGIN_SUCCESS:
            return { ...state, userData: payload };
        case LOGIN_FAILED:
            return { ...state, error: payload };
        case LOGOUT:
            return { ...state };


        case BIDGRID_DATA_REQUEST:
            return { ...state };
        case BIDGRID_DATA_SUCCESS:
            return { ...state, bidgridData: payload };
        case BIDGRID_DATA_FAILED:
            return { ...state, error: payload };
        case LOGOUT_BID:
            return { ...state };

        case STATGRID_DATA_REQUEST:
            return { ...state };
        case STATGRID_DATA_SUCCESS:
            return { ...state, statgridData: payload };
        case STATGRID_DATA_FAILED:
            return { ...state, error: payload };
        case LOGOUT_STAT:
            return { ...state };
        case NEW_APP_LAUNCH:
            return { ...initialState }

        default:
            return state;
    }
};
