import { APIService } from "../../APIService";
import { baseUrl, isAuth } from "../../../utils/configurable";
import { authHeader } from '../../../helper/authHeader'

const getNavBar = async (data) => {

    try {
        const response = await APIService.post(`${baseUrl}/GetAllAppMenu`, data);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getSeoRequest = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/get_seo_request`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const getAdvanceSeo = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    const response = await APIService.post(`${baseUrl}/get_advanced_seo`, data, { headers });
    return response
}

const getCoppyUrlSeo = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    const response = await APIService.post(`${baseUrl}/get_id_by_name`, data, { headers });
    return response

}


const getAllContentByPageName = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/list_content_seo`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


export const NavBarMenu = {
    getNavBar,
    getSeoRequest,
    getAdvanceSeo,
    getCoppyUrlSeo,
    getAllContentByPageName
};