// @ts-nocheck
import { DROPDOWN_REQUEST, BID_DROPDOWN_RESETALL, DROPDOWN_FAILED, DROPDOWN_RESETALL, DROPDOWN_SUCCESS, SEO_REQUEST, SEO_SUCCESS, SEO_FAILED, DYNAMIC_MENU_REQUEST, DYNAMIC_MENU_SUCCESS, DYNAMIC_MENU_FAILED, BID_DROPDOWN_REQUEST, BID_CLIENT_SUCCESS, BID_DROPDOWN_FAILED, BID_CLIENT_FUNDING_AGENCY_SUCCESS, DROPDOWN_MENU_RESET, BID_COUNTRY_SUCCESS, BID_SECTOR_SUCCESS, STAT_CLIENT_SUCCESS, STAT_FUNDING_SUCCESS, STAT_TENDER_CAT_SUCCESS, STAT_DROPDOWN_FAILED, STAT_DROPDOWN_REQUEST, STAT_DROPDOWN_RESETALL, STAT_TENDER_COUNTRY_SUCCESS, STAT_TENDER_STATE_SUCCESS, STAT_SECTOR_SUCCESS } from "../../constants/common/DropdownConstant"
import { NavBarMenu } from '../../../Services/common/navbar/NavBarMenu'

export const dropdownValAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: DROPDOWN_REQUEST })
        dispatch({ type: DROPDOWN_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: DROPDOWN_FAILED, payload: error })
    }
}

export const dynamicMenuAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: DYNAMIC_MENU_REQUEST })
        dispatch({ type: DYNAMIC_MENU_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: DYNAMIC_MENU_FAILED, payload: error })
    }
}

export const dropdownValResetAction = () => async (dispatch) => {

    dispatch({ type: DROPDOWN_RESETALL })

}
export const dropdownMenuResetAction = () => async (dispatch) => {

    dispatch({ type: DROPDOWN_MENU_RESET })

}
export const bidClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CLIENT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}


export const bidFundingClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CLIENT_FUNDING_AGENCY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidCountryAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_COUNTRY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}


export const bidSectorAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_SECTOR_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}



export const bidDropdownActionReset = () => async (dispatch) => {

    dispatch({ type: BID_DROPDOWN_RESETALL })

}


export const StatClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_CLIENT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}


export const StatFundingAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_FUNDING_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}

export const StatTenderCatTypeAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_TENDER_CAT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}

export const StatCountryTypeAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_TENDER_COUNTRY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}


export const StatStateTypeAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_TENDER_STATE_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}

export const StatSectorAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_SECTOR_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}
export const statDropdownActionReset = () => async (dispatch) => {

    dispatch({ type: STAT_DROPDOWN_RESETALL })

}

export const seoValuesAction = (id, tender_id) => {
    return (dispatch) => {
        dispatch({ type: SEO_REQUEST });
        const formdata = new FormData();
        formdata.append('page_id', id)
        formdata.append("tender_id", tender_id ? tender_id : "")
        if (id && tender_id) {
            NavBarMenu.getSeoRequest(formdata)
                .then((res) => {
                    if (res?.data?.status === 1) {
                        dispatch({
                            type: SEO_SUCCESS,
                            payload: res?.data?.data,
                        });
                    } else {
                        dispatch({
                            type: SEO_FAILED,
                            payload: 'Error message',
                        });
                    }
                })
        }
    };
};
