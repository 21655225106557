import axios from "axios";
import has from "lodash/has"
import { verifyAuth } from '../utils/auth'

class APIServiceFactory {
    post = async function (url, data, config = {}) {
        const isInvalidAuthToken = has(config.headers, "Auth-Token") && !config.headers["Auth-Token"]

        if (isInvalidAuthToken) {
            console.log(`Invalid auth header for url ${url}`)
            return
        }
        const response = await axios.post(url, data, config)
        verifyAuth(response)
        return response
    }


    get = async function (url, config = {}) {
        const isInvalidAuthToken = has(config.headers, "Auth-Token") && !config.headers["Auth-Token"];

        if (isInvalidAuthToken) {
            console.log(`Invalid auth header for url ${url}`);
            return;
        }

        const response = await axios.get(url, config);
        verifyAuth(response);
        return response;
    }
}

export const APIService = new APIServiceFactory();
